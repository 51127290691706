import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Base64 } from '@ionic-native/base64/ngx';
import { FormBuilder, FormGroup, Validators, FormControl,  } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AntibioticoComponent } from './componentes/antibiotico/antibiotico.component';
import { BalancoComponent } from './componentes/balanco/balanco.component';
import { LaboratorioComponent } from './componentes/laboratorio/laboratorio.component';
import { CpisComponent } from './componentes/cpis/cpis.component';
import { DispositivoComponent } from './componentes/dispositivo/dispositivo.component';
import { EcocardioComponent } from './componentes/ecocardio/ecocardio.component';
import { FasthugComponent } from './componentes/fasthug/fasthug.component';
import { GeralComponent } from './componentes/geral/geral.component';
import { MedicamentoComponent } from './componentes/medicamento/medicamento.component';
import { NutricComponent } from './componentes/nutric/nutric.component';
import { SofaComponent } from './componentes/sofa/sofa.component';
import { VentilacaoComponent } from './componentes/ventilacao/ventilacao.component';
import { VitalComponent } from './componentes/vital/vital.component';
import { Saps3Component } from './componentes/saps3/saps3.component';
import { ComorbidadeComponent } from './componentes/comorbidade/comorbidade.component';
import { HistoriaclinicaComponent } from './componentes/historiaclinica/historiaclinica.component';
import { BioquimicaComponent } from './componentes/laboratorio/bioquimica/bioquimica.component';
import { CoagulogramaComponent } from './componentes/laboratorio/coagulograma/coagulograma.component';
import {FezesComponent } from './componentes/laboratorio/fezes/fezes.component';
import {GasometriaComponent } from './componentes/gasometria/gasometria.component';
import {HemogramaComponent } from './componentes/laboratorio/hemograma/hemograma.component';
import {HormonioComponent } from './componentes/laboratorio/hormonio/hormonio.component';
import {ImunologiaComponent } from './componentes/laboratorio/imunologia/imunologia.component';
import {MarcadorComponent } from './componentes/laboratorio/marcador/marcador.component';
import {UrinaComponent } from './componentes/laboratorio/urina/urina.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FotousuarioComponent } from './componentes/fotousuario/fotousuario.component';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FotopacienteComponent } from './componentes/fotopaciente/fotopaciente.component';
import { ChecklistComponent } from './componentes/checklist/checklist.component';
import { ChecklistescoreComponent } from './componentes/checklistescore/checklistescore.component';
import { DrogaComponent } from './componentes/droga/droga.component';
import { PatologiainfoComponent } from './componentes/patologiainfo/patologiainfo.component';
import { File } from '@ionic-native/File/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@ionic-native/media-capture/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import {GraficoComponent } from './componentes/grafico/grafico.component';
import {GlasgowComponent } from './componentes/glasgow/glasgow.component';
import {DeliriumComponent } from './componentes/delirium/delirium.component';
import {ChadsvascComponent } from './componentes/chadsvasc/chadsvasc.component';
import { AltaComponent } from './componentes/alta/alta.component';
import { TaginfoComponent } from './componentes/taginfo/taginfo.component';
import { PopoverComponent } from './componentes/popover/popover.component';
import { TermodeusoComponent } from './componentes/termodeuso/termodeuso.component';
import { ImagemComponent } from './componentes/imagem/imagem.component';
import { IntubacaoComponent } from './componentes/intubacao/intubacao.component';
import { HemodinamicaComponent } from './componentes/hemodinamica/hemodinamica.component';
import { ExameconclusaoComponent } from 'src/app/componentes/exameconclusao/exameconclusao.component';
import { ExamepadraoComponent } from 'src/app/componentes/examepadrao/examepadrao.component';
import { ExamesolicitanteComponent } from 'src/app/componentes/examesolicitante/examesolicitante.component';
import { ExamerelacaoComponent } from 'src/app/componentes/examerelacao/examerelacao.component';
import { ExamemedidaComponent } from 'src/app/componentes/examemedida/examemedida.component';
import { ExameunidadeComponent } from 'src/app/componentes/exameunidade/exameunidade.component';
import { ExameorgaoComponent } from 'src/app/componentes/exameorgao/exameorgao.component';
import { ExametopicoComponent } from 'src/app/componentes/exametopico/exametopico.component';
import { ExameformatarComponent } from 'src/app/componentes/exameformatar/exameformatar.component';
import { EventoedicaoComponent } from 'src/app/componentes/eventoedicao/eventoedicao.component';
import { ObstetriciaComponent } from 'src/app/componentes/obstetricia/obstetricia.component';
import { CursoComponent } from 'src/app/componentes/curso/curso.component';
import { FluxogramasistemaComponent } from 'src/app/componentes/fluxogramasistema/fluxogramasistema.component';
import { FluxogramaescoreComponent } from 'src/app/componentes/fluxogramaescore/fluxogramaescore.component';
import { FluxogramarelacaoComponent } from 'src/app/componentes/fluxogramarelacao/fluxogramarelacao.component';
import { EstudorespostaComponent } from 'src/app/componentes/estudoresposta/estudoresposta.component';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

@NgModule({
  declarations: [
    AppComponent,
    AntibioticoComponent,
    BalancoComponent,
    LaboratorioComponent,
    CpisComponent,
    DispositivoComponent,
    EcocardioComponent,
    FasthugComponent,
    GeralComponent, 
    MedicamentoComponent,
    NutricComponent,
    SofaComponent,
    VentilacaoComponent,
    VitalComponent,
    Saps3Component,
    ComorbidadeComponent,
    HistoriaclinicaComponent,
    BioquimicaComponent,
    CoagulogramaComponent,
    FezesComponent,
    GasometriaComponent,
    HemogramaComponent,
    HormonioComponent,
    ImunologiaComponent,
    MarcadorComponent,
    UrinaComponent,
    FotousuarioComponent,
    FotopacienteComponent,
    ChecklistComponent,
    ChecklistescoreComponent,
    DrogaComponent,
    PatologiainfoComponent,
    GraficoComponent,
    GlasgowComponent,
    DeliriumComponent,
    ChadsvascComponent,
    AltaComponent,
    TaginfoComponent,
    PopoverComponent,
    TermodeusoComponent,
    ImagemComponent,
    IntubacaoComponent,
    HemodinamicaComponent,
    ExameconclusaoComponent,
    ExamepadraoComponent,
    ExamesolicitanteComponent,
    ExamerelacaoComponent,
    ExamemedidaComponent,
    ExameunidadeComponent,
    ExameorgaoComponent,
    ExametopicoComponent,
    ExameformatarComponent,
    EventoedicaoComponent,
    ObstetriciaComponent,
    CursoComponent,
    FluxogramasistemaComponent,
    FluxogramaescoreComponent,
    FluxogramarelacaoComponent,
    EstudorespostaComponent
    ],
  entryComponents: [
    AntibioticoComponent,
    BalancoComponent,
    LaboratorioComponent,
    CpisComponent,
    DispositivoComponent,
    EcocardioComponent,
    FasthugComponent,
    GeralComponent, 
    MedicamentoComponent,
    NutricComponent,
    SofaComponent,
    VentilacaoComponent,
    VitalComponent,
    Saps3Component,
    ComorbidadeComponent,
    HistoriaclinicaComponent,
    BioquimicaComponent,
    CoagulogramaComponent,
    FezesComponent,
    GasometriaComponent,
    HemogramaComponent,
    HormonioComponent,
    ImunologiaComponent,
    MarcadorComponent,
    UrinaComponent,
    FotousuarioComponent,
    FotopacienteComponent,
    ChecklistComponent,
    ChecklistescoreComponent,
    DrogaComponent,
    PatologiainfoComponent,
    GraficoComponent,
    GlasgowComponent,
    DeliriumComponent,
    ChadsvascComponent,
    AltaComponent,
    TaginfoComponent,
    PopoverComponent,
    TermodeusoComponent,
    ImagemComponent,
    IntubacaoComponent,
    HemodinamicaComponent,
    ExameconclusaoComponent,
    ExamepadraoComponent,
    ExamesolicitanteComponent,
    ExamerelacaoComponent,
    ExamemedidaComponent,
    ExameunidadeComponent,
    ExameorgaoComponent,
    ExametopicoComponent,
    ExameformatarComponent,
    EventoedicaoComponent,
    ObstetriciaComponent,
    CursoComponent,
    FluxogramasistemaComponent,
    FluxogramaescoreComponent,
    FluxogramarelacaoComponent,
    EstudorespostaComponent
    ],
  imports: [
    ReactiveFormsModule, 
    BrowserModule, 
    //IonicModule.forRoot(), 
    IonicModule.forRoot(
      { mode: 'md' }
    ),
    AppRoutingModule, 
    HttpClientModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Clipboard,
    Base64,
    FormBuilder,
    FileTransfer,
    Camera,
    FileOpener,
    File,
    MediaCapture,
    NativeAudio,
    Media,
    StreamingMedia,
    TextToSpeech,
   //   { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
      { provide: LocationStrategy, useClass: HashLocationStrategy }

  ],
  bootstrap: [AppComponent]
  
})
//lenander

export class AppModule {}
